@font-face {
    font-family: 'BebasNeue';
    src: url('./assets/fonts/BebasNeue\ Book.otf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'LisuBosa';
    src: url('./assets/fonts/Lisu_Bosa/LisuBosa-Black.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Slabo';
    src: url('./assets/fonts/Slabo27px-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-VariableFont_wdth\,wght.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('./assets/fonts/Manrope-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Jakarta';
    src: url('./assets/fonts/PlusJakartaSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Barlow';
    src: url('./assets/fonts/Barlow-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Barlow';
    src: url('./assets/fonts/Barlow-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Barlow-800';
    src: url('./assets/fonts/Barlow-ExtraBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BarlowCN';
    src: url('./assets/fonts/BarlowCondensed-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BarlowCN-800';
    src: url('./assets/fonts/BarlowCondensed-ExtraBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  .my-custom-checkpicker .rs-picker-check {
    /* Custom styles go here */
    font-size: 8px;
}
