.custom-navbar {
    background-color: #fff; /* Change the navbar background color */
    font-family: 'Barlow', sans-serif; /* Use your main font */
    padding: 10px 20px; /* Adjust padding */
}

.custom-navbar .navbar-brand {
    font-size: 1.5rem; /* Adjust brand font size */
    color: #F7F3F2; /* Brand text color */
    font-weight: bold;
    letter-spacing: 1px;
}

.custom-navbar .navbar-nav .nav-link {
    color: #121212; /* Navbar link color */
    font-size: 1rem;
    margin-right: 15px;
    transition: color 0.3s ease;
}

.custom-navbar .navbar-nav .nav-link:hover {
    color: #FFD700; /* Change link color on hover */
    text-decoration: underline; /* Optional hover effect */
}

.custom-navbar .navbar-toggler {
    border-color: #F7F3F2; /* Toggler border color */
    background-color: #00589B; /* Toggler background */
}

.custom-navbar .navbar-toggler-icon {
    background-image: none; /* Remove default toggler icon */
    width: 24px;
    height: 2px;
    background-color: #F7F3F2;
    position: relative;
}

.custom-navbar .navbar-toggler-icon::before,
.custom-navbar .navbar-toggler-icon::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #F7F3F2;
    transition: all 0.3s ease;
}

.custom-navbar .navbar-toggler-icon::before {
    top: -8px;
}

.custom-navbar .navbar-toggler-icon::after {
    top: 8px;
}
