.datepicker-container {
    position: relative;
  }
  
  .datepicker-label {
    display: block;
    margin-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  
  .datepicker-input {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    height: 35px;
  }
  
  .datepicker-input:focus {
    border-color: #66afe9;
    outline: none;
  }
  
  .datepicker-input-invalid {
    border-color: red;
  }
  
  .datepicker-error {
    color: red;
    margin-top: 10px;
    font-size: 12px;
  }
  
  .datepicker-selected-date {
    margin-top: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  